import React, { useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import {
  Route,
  Redirect,
  Switch,
  BrowserRouter as Router,
} from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import { Flex, Bloc, Box } from "blocjs";

import theme from "./theme";
import GlobalStyle from "./global.style";
import { Navigation, Footer } from "./components";

import { Login, Profile, SamplePage, Feedback } from "./pages";
import { APIService, configCallback } from "./services";
import { authenticatedAction, loadConfigAction, completedAction } from "./actions";
import Summary from "./pages/summary";

const App = (props) => {
  const api = new APIService();
  const [loaded, setLoaded] = useState(false);
  const { state, actions } = useStateMachine({
    loadConfigAction,
    authenticatedAction,
    completedAction
  });

  useEffect(() => {
    api.getConfig(configCallback).then((data) => {
      actions.loadConfigAction(data);
      setLoaded(true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Router>
        <Flex flexWrap="wrap" flex={1} width={1} pb={[350, 172]}>
          <Navigation />

          <Switch>
            <Protected path="/feedback" hasPerm={state.authenticated}>
              <Feedback />
            </Protected>
            <Protected
              path="/samples/:samplePosition"
              hasPerm={state.authenticated && !state.completed}
            >
              <SamplePage />
            </Protected>
            <Protected
              path="/summary"
              hasPerm={state.authenticated && !state.completed}
            >
              <Summary />
            </Protected>
            <Protected path="/profile" hasPerm={state.authenticated}>
              <Profile />
            </Protected>
            <AutoLogin exact path="/" hasPerm={state.authenticated}>
              <Login />
            </AutoLogin>

            <Route exact path="/access-denied">
              <Flex flexWrap="wrap" width={1} maxWidth={1240} mx="auto">
                <Bloc as="p" fontSize={3} fontWeight="bold" color="surfaces.2">
                  <Box width={1} mx={["5.55555%"]}>
                    Access denied
                  </Box>
                </Bloc>
              </Flex>
            </Route>
            <Route exact path="/page-not-found">
              <Flex flexWrap="wrap" width={1} maxWidth={1240} mx="auto">
                <Bloc as="p" fontSize={3} fontWeight="bold" color="surfaces.2">
                  <Box width={1} mx={["5.55555%"]}>
                    Page not found
                  </Box>
                </Bloc>
              </Flex>
            </Route>
            <Redirect to="/page-not-found" />
          </Switch>

          <Footer />
        </Flex>
      </Router>
    </ThemeProvider>
  );
};

const AutoLogin = ({ hasPerm, children, ...rest }) => {
  return (
    <Route
      render={({ location }) =>
        hasPerm ? (
          <Redirect
            to={{
              pathname: "/profile",
              state: { from: location },
            }}
          />
        ) : (
          children
        )
      }
      {...rest}
    />
  );
};

const Protected = ({ hasPerm, children, ...rest }) => {
  return (
    <Route
      render={({ location }) =>
        hasPerm ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/access-denied",
              state: { from: location },
            }}
          />
        )
      }
      {...rest}
    />
  );
};

export default App;
