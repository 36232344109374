import ky from "ky";

class APIService {
  constructor() {
    this.url = "https://script.google.com/macros/s/AKfycbzgqSZxmhpkJV_msvpTRsc80Jj67zJRvtv2WxunsgdVceZZHexwmdUK/exec";
    this.resultURL = "https://script.google.com/macros/s/AKfycbzPtb4hkfQU0SEMsB5vSFCSYZg8_bqxulsh-gEG5ttvS7wd4k4hb5EqG8JbvLX_LX055A/exec";
    // this.resultURL = "https://script.google.com/macros/s/AKfycbxHxPp8g0JGVPH9w9_mpdnXP_PGsr9H3celSBI50wkQKJI6nl6BMkd7eQ/exec";
  }

  verifyToken = async (token, callback) => {
    callback(
      await ky.get(`${this.url}?action=verify_token&token=${token}`).json()
    );
  };

  getConfig = async callback => {
    const resp = await ky.get(`${this.url}?action=get_config`).json();
    return callback(resp);
  };

  getResults = async (id, configs, pos, callback) => {

    const labId = (id || '').toString().padStart(4, "0");

    const payload = {
      "action": 'get_results',
      "lab_id": labId,
      "configs": JSON.stringify(configs),
      "pos": pos
    };

    fetch(`${this.resultURL}`, {
      redirect: "follow",
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "text/plain;charset=utf-8",
      },
    }).then(data => {
      data.json().then(rsp => {
        callback(rsp);
      })
    }).catch(err => {
      console.error(err);
      throw new Error(`The following error occured at 'get_results'. \nERROR: ${err}`);
    });
  };

  sendResults = async (id, pos, sampleState, indexer, callback) => {
    let labId = (id || '').toString().padStart(4, "0");

    const payload = {
      action: 'send_results',
      lab_id: labId,
      pos: pos,
      indexer: indexer,
      payload: sampleState
    };

    fetch(`${this.resultURL}`, {
      redirect: "follow",
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "text/plain;charset=utf-8",
      },
    }).then(data => {
      data.json().then(rsp => {
        callback(rsp);
      })
    }).catch(err => {
      console.error(err);
      throw new Error(`The following error occured at 'send_results'. \nERROR: ${err}`);
    });
  };

  completeForm = async (token, callback) => {
    const resp = await ky.get(`${this.url}?action=complete_form&token=${token}`).json();
    return callback(resp);
  };

  sendFeedback = async (id, data, callback) => {
    let labId = (id || '').toString().padStart(4, "0");
    const resp = await ky
      .get(
        `${this.resultURL}?action=send_feedback&lab_id=${labId}&payload=${JSON.stringify(
          data
        )}`
      )
      .json();
    return callback(resp);
  }

  fetchResultSummary = async (id, callback) => {
    const labId = id;
    const payload = {
      "action": 'get_result_summary',
      "lab_id": labId
    };

    fetch(`${this.resultURL}`, {
      redirect: "follow",
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "text/plain;charset=utf-8",
      },
    }).then(data => {
      data.json().then(rsp => {
        callback(rsp);
      })
    }
    ).catch(err => {
      console.error(err);
      throw new Error(`The following error occured at 'get_result_summary'. \nERROR: ${err}`);
    }
    );
  };

}

export default APIService;
