import React, { useState } from "react";
import { Bloc } from "blocjs";

const Inputfield = React.forwardRef(
  ({ name, placeholder, error, ...rest }, ref) => {
    const [isNA, setIsNA] = useState(true);

    const handleValueChange = (e) => {
      e.preventDefault();
      setIsNA(false);
    };

    return (
      <Bloc
        px={4}
        py={3}
        width={1}
        minWidth={40}
        border={2}
        borderRadius={2}
        borderColor={error ? "tomato" : "accents.1"}
        fontSize={18}
        fontFamily="mono"
        as="input"
        name={name}
        placeholder={placeholder}
        onChange={handleValueChange}
        color={isNA ? 'tomato' : undefined}
        ref={ref}
        {...rest}
      />
    );
  }
);

export default Inputfield;
