import React from "react";
import ReactDOM from "react-dom";
import { DevTool } from "little-state-machine-devtools";
import { StateMachineProvider, createStore } from "little-state-machine";
import useCookie from "@devhammed/use-cookie";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

const Root = () => {
  const [authenticated, setAuthenticated] = useCookie("authenticated");
  authenticated === undefined && setAuthenticated(false);
  if (process.env.NODE_ENV !== "production") {
    console.log("Do I have authenticated", authenticated);
  }

  createStore({
    configDetails: [],
    loaded: false,
    completed: false,
    authenticated: authenticated,
    token: undefined
  });

  return (
    <React.StrictMode>
      <StateMachineProvider>
        {process.env.NODE_ENV !== 'production' && <DevTool />}
        <App />
      </StateMachineProvider>
    </React.StrictMode>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
