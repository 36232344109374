import React from "react";

import styled from "styled-components";
import { Bloc } from "blocjs";

const BlocStyle = styled(Bloc)({
  border: "none",
  cursor: props => (props.disabled ? "not-allowed" : "pointer"),
  fontFamily: "inherit",
  outline: "none"
});

const Button = ({ bg = "accent", type = "button", disabled, ...rest }) => {
  return (
    <BlocStyle
      px={5}
      py={4}
      fontSize={1}
      borderRadius={3}
      color="surface"
      bg={disabled ? "primaries.7" : bg}
      as="input"
      type={type}
      disabled={disabled}
      {...rest}
    />
  );
};

export default Button;
