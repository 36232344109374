import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import useCookie from "@devhammed/use-cookie";
import { Bloc, Flex } from "blocjs";

import { authenticatedAction, completedAction } from "../../actions";
import logo from "../../assets/images/logo.svg";

const Navigation = () => {
  const { replace } = useHistory();
  const { state, actions } = useStateMachine({ authenticatedAction, completedAction });
  const [, setAuthenticated] = useCookie("authenticated");
  // const [, setLabDetails] = useCookie("labDetails");

  const handleLogin = () => {
    actions.completedAction(false);
  };

  const handleLogout = () => {
    actions.authenticatedAction(false);
    setAuthenticated(false);
    // setLabDetails({});
    localStorage.setItem("labDetails", JSON.stringify({}));
    replace("/");
  };

  return (
    <Bloc width={1} py={4} bg="primaries.2">
      <Flex mx="5.55555%">
        <Bloc
          as={Link}
          to="/"
          width={204}
          height={40}
          mr="auto"
          backgroundSize="contain"
          backgroundRepeat="no-repeat"
          backgroundImage={`url(${logo})`}
        />
        <Bloc color="primaryLight" fontWeight="bold" hide={[1, 0]}>
          GLOSOLAN
          <Bloc fontSize={9} fontWeight="normal">
            Global Soil Laboratory Network Platform
          </Bloc>
        </Bloc>
        <Flex justifyContent="right" ml="auto" width={204}>
          {state.authenticated ? (
            <>
              <Bloc
                as={Link}
                to="/profile"
                pt="12px"
                pb={3}
                px={4}
                ml="auto"
                fontSize={1}
                height={40}
                minWidth={40}
                borderRadius={5}
                color="surface"
                bg="accents.2"
              >
                Profile
              </Bloc>
              <Bloc
                as={Link}
                to="/"
                pt="12px"
                pb={3}
                px={4}
                ml="auto"
                fontSize={1}
                height={40}
                minWidth={40}
                borderRadius={5}
                color="surface"
                bg="primaries.0"
                onClick={handleLogout}
              >
                Logout
              </Bloc>
            </>
          ) : (
            <Bloc
              as={Link}
              to="/"
              pt="12px"
              pb={3}
              px={4}
              ml="auto"
              fontSize={1}
              height={40}
              minWidth={40}
              borderRadius={5}
              color="surface"
              bg="accents.2"
              onClick={handleLogin}
            >
              Login
            </Bloc>
          )
          }
        </Flex>
      </Flex>
    </Bloc>
  );
};

export default Navigation;
