import React from "react";
import { useHistory } from "react-router-dom";
import useCookie from "@devhammed/use-cookie";
import { useStateMachine } from "little-state-machine";
import { Box, Bloc, Flex } from "blocjs";

import { Button } from "../../components";
import { loadedAction } from "../../actions";

const Profile = (props) => {
  const { push } = useHistory();
  const { state, action } = useStateMachine(loadedAction);
  // const [labDetails] = useCookie("labDetails");
  const labDetails = JSON.parse(localStorage.getItem("labDetails"));

  // PATCH: issue with config data not being loaded until refresh
  if (!state.loaded) {
    window.location.reload();
    action(true);
  }

  return (
    <Flex flexWrap="wrap" width={1} maxWidth={1240} mx="auto">
      <Box width={1} mx={["5.55555%"]}>
        <Bloc
          color="surfaces.2"
          bg="surface"
          width={1}
          p={5}
          my={5}
          borderRadius={3}
        >
          <Flex>
            <Bloc flex={1}>
              <Bloc fontSize={3} fontWeight="bold">
                {labDetails?.acronym}
              </Bloc>
              <Box>{labDetails?.name}</Box>
              <Bloc bg="accents.4" width={64} height={1} my={4} />
              <Box>{labDetails?.address}</Box>
              <Box>{labDetails?.country}</Box>
            </Bloc>
            <Bloc flex={1} textAlign="right">
              <Bloc fontWeight="bold">{`${labDetails?.labHeadNames}`}</Bloc>
              <Bloc fontSize={1}>{labDetails?.labHeadPosition}</Bloc>
              <Bloc bg="accents.4" width={64} height={1} ml="auto" my={4} />
              <Bloc fontWeight="bold">{`${labDetails?.contactPersonNames}`}</Bloc>
              <Bloc fontSize={1}>{labDetails?.contactPersonPosition}</Bloc>
            </Bloc>
          </Flex>
        </Bloc>
        <Box width={1}>
          {state.completed ? (
            <>
              <Bloc color="accents.0" bg="accents.6" p={5} borderRadius={3}>
                Your results submission was successfully completed <br />
                <br />
                Thank you for participating in the GLOSOLAN PT and for
                submitting the results of your analysis. GLOSOLAN will inform
                you about the performance of your laboratory as soon as possible
                once the results of all participating laboratories are collated.
                If you have any questions please contact the GLOSOLAN
                coordinator, Ms. Lucrezia Caon at lucrezia.caon@fao.org
              </Bloc>
              <Bloc my={7}></Bloc>
            </>
          ) : (
            <>
              <Button
                as="span"
                display="inline-block"
                fontSize={2}
                py={5}
                px={6}
                onClick={() => push("/samples/0")}
              >
                Open soil testing form
              </Button>
              <Bloc mt={8} fontSize={3} fontWeight="bold" color="surfaces.2">Form Instructions</Bloc>
              <Bloc as="ol" color="surfaces.2" mb={8}>
                <li>
                  You can save your progress by clicking on the “Save Draft” button
                  or simply move to the next screen by clicking “Next” button
                </li>
                <li>
                  You can also move back to cross-check and edit the data you entered
                </li>
                <li>
                  All fields should be completed. The system automatically notifies when invalid data
                  are entered. For ease of use we have prefilled all fields with NA (“Not Analyzed”)
                </li>
                <li>
                  If you did not perform an analysis,
                  please leave in NA (do not write 0 as 0 is not a valid number)
                </li>
                <li>
                  If your result is lower than your detection limit please insert ND (“Not Detected”)
                </li>
                <li>
                  Do not use inequality symbols like &lt; or &gt;
                </li>
                <li>
                  Do not use commas (,) to separate decimals, instead use dots (.).
                  For example, “0,098” is an invalid entry. “0.098” is a valid entry
                </li>
                <li>
                  Enter data in the right units of measure. The units of measure required are reported
                  in the instructions document you received with the samples and next to the soil
                  parameter to analyze in this platform.
                </li>
                <li>
                  It is strongly recommended that you cross check all data entered before clicking
                  on “SUBMIT”. Once you press “submit”, you will not be able to edit your results anymore
                </li>
                <li>
                  After you press “submit”, you will be asked to report on your experience
                  in doing the analysis and using this online application.
                </li>
              </Bloc>
            </>
          )}
        </Box>
      </Box>
    </Flex>
  );
};

export default Profile;
