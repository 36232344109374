import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import { updateConfigDataAction, tokenAction, completedAction } from "../../actions";
import { APIService } from '../../services';
import { Flex, Box, Bloc } from 'blocjs';
import Button from '../../components/button';
import Table from '../../components/table';
import EditIcon from '../../components/icon';

const VALUE_START_AT = 3;

const Summary = () => {

    const api = new APIService();

    const { state, actions } = useStateMachine({
        updateConfigDataAction,
        tokenAction,
        completedAction
    });

    const { push } = useHistory();
    const [drafting, setDrafting] = useState(false);
    const [processing, setProcessing] = useState(false);

    const [data, setData] = useState([]);

    const isDoneLoading = (data.length !== 0);


    useEffect(() => {
        const getId = () => {
            const token = state?.token;
            // Token is a 10 digit number and Id is a 4 digit number that starts on the 5th index and ends on the 8th index
            const id = (token || '').slice(4, 8);
            return id;
        };

        const _id = getId();
        api.fetchResultSummary(_id, ({ data }) => {
            setData(data);
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state?.token]);

    const onPreviousClicked = () => {
        setProcessing(true);
        // assuming that total length of samples
        const totalSamples = state.configDetails.length;
        const pos = totalSamples - 1;
        push(`/samples/${pos}`);
    };

    const handleSubmit = () => {
        setProcessing(true);
        // save and submit
        api.completeForm(state.token, (resp) => {
            resp["status"] === "SUCCESS" && push("/feedback");
        });
    };

    const onDraftSubmit = (e) => {
        e.preventDefault();
    };

    return (
        isDoneLoading
            ? <Box
                as={'div'}
                width={'100%'}
                FlexDirection='column'
                FlexWrap={'wrap'}
                justifyContent={'center'}
                alignItems={'center'}
                alignContent={'center'}
                padding={'1rem'}

            >

                <Bloc
                    as="p"
                    fontSize={3}
                    fontWeight="bold"
                    color="surfaces.2"
                    textAlign="center"
                >
                    {"Sample Summary"}
                </Bloc>

                <Table>

                    <Bloc
                        as={'thead'}
                    >
                        <Bloc
                            as={'tr'}
                            borderBottom={'1px solid #000'}
                            textAlign={'center'}
                            padding={'0.5rem'}
                        >
                            <Bloc
                                as={'th'}
                                borderRight={'1px solid #000'}
                            >
                                &nbsp;
                            </Bloc>
                            {(state.configDetails || []).map((item, _index) => {
                                return (
                                    <Bloc
                                        as={'th'}
                                        key={item.name}
                                        alignContent={'center'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                        textAlign={'center'}
                                        padding={'1rem 0'}
                                        borderBottom={'1px solid #000'}
                                    >
                                        <Flex
                                            as={'div'}
                                            justifyContent={'space-between'}
                                            flexDirection={'column-reverse'}
                                            padding={'0 0.25rem'}
                                        >
                                            <Bloc
                                                as='p'
                                                color='surfaces.2'
                                                textAlign={["center", "center"]}
                                                fontSize={16}
                                                width={1}
                                                mt={2}
                                                mb={1}
                                            >
                                                {item.name}
                                            </Bloc>
                                            <Button
                                                as="div"
                                                display="flex"
                                                arial-label={`Go to ${item.name}`}
                                                title={`Go to ${item.name}`}
                                                fontSize={1}
                                                small
                                                py={1}
                                                px={1}
                                                bg="primaryDark"
                                                onClick={() => {
                                                    setProcessing(true);
                                                    push(`/samples/${_index}`)
                                                }}
                                                textAlign={["center", "center"]}
                                            >
                                                <Flex
                                                    as={'div'}
                                                    justifyContent={'space-evenly'}
                                                    alignContent={'center'}
                                                    flexDirection={'row'}
                                                    padding={'0'}
                                                    width={1}
                                                    m={'auto'}
                                                >
                                                    <EditIcon fill='#FFFFFF' />
                                                    <Bloc
                                                        as='p'
                                                        color='surfaces.0'
                                                        fontSize={12}
                                                        m={'0'}
                                                        p={'1'}
                                                    >
                                                        Edit
                                                    </Bloc>
                                                </Flex>
                                            </Button>
                                        </Flex>
                                    </Bloc>
                                )
                            })}
                        </Bloc>
                    </Bloc>

                    <Bloc
                        as={'tbody'}
                        key={'tbody'}
                    >
                        {(data || []).map((_row) => {

                            const _curRowName = _row[1];

                            return (
                                <Bloc
                                    as={'tr'}
                                    key={_curRowName}
                                    textAlign={'right'}
                                    padding={'0.5rem'}
                                    borderBottom={'solid black 0.1rem;'}
                                >
                                    <Bloc
                                        as={'td'}
                                        padding={'0.5rem'}
                                        borderRight={'1px solid #000'}
                                    >
                                        <b>{_row[1]}</b>&nbsp;<i>{_row[2]}</i>
                                    </Bloc>

                                    {_row.slice(VALUE_START_AT, Number(state?.configDetails?.length) + VALUE_START_AT).map((_values, _rowIndex) => {
                                        const _rowId = _row[0] + '-' + _rowIndex;
                                        return (
                                            <Bloc
                                                as={'td'}
                                                key={_rowId}
                                                alignContent={'center'}
                                                justifyContent={'center'}
                                                alignItems={'center'}
                                                textAlign={'center'}
                                                padding={'1rem 0.5rem'}
                                            >
                                                {_values || '-'}
                                            </Bloc>

                                        );
                                    })}
                                </Bloc>
                            )
                        })}

                    </Bloc>
                </Table>

                <Bloc
                    px={5}
                    py={3}
                    width={1}
                    backgroundColor="primaries.7"
                    style={{ borderRadius: 2 }}
                >
                    <Bloc
                        as="p"
                        fontSize={3}
                        fontWeight="bold"
                        color="surfaces.2"
                    >
                        <big>Attention:</big> Please review your data before pressing "SUBMIT". Once submitted, changes cannot be reverted.
                    </Bloc>
                </Bloc>

                <Flex justifyContent="space-between" width={1} my={8}>
                    <Button
                        value="Previous"
                        fontSize={3}
                        fontWeight="bold"
                        onClick={() => {
                            onPreviousClicked();
                        }}
                        disabled={processing || drafting}
                        type="button"
                    />
                    <Button
                        type="button"
                        value={drafting ? "Saving draft..." : "Save draft"}
                        fontSize={3}
                        fontWeight="bold"
                        onClick={(e) => {
                            onDraftSubmit(e);
                        }}
                        disabled={true}
                    />
                    <Button
                        type="button"
                        bg={"primary"}
                        value={"Submit"}
                        fontSize={3}
                        fontWeight="bold"
                        onClick={() => {
                            handleSubmit();
                        }}
                        disabled={processing || drafting}
                    />

                </Flex>
            </Box>
            : <Flex width={1}>
                <Bloc
                    mt={91}
                    pt={86}
                    p="auto"
                    width={1}
                    height={320}
                    color="surfaces.2"
                    border={2}
                    borderRadius={3}
                    borderColor="accents.2"
                    backgroundColor="surfaces.0"
                    fontSize={3}
                    fontWeight="bold"
                    textAlign="center"
                    margin={"10%"}
                >
                    Loading Data...
                </Bloc>
            </Flex>
    )


}

export default Summary;