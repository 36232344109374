import { createGlobalStyle } from "styled-components";

import MonoWOFF from "./assets/fonts/iosevka-ss08-regular.woff";
import MonoWOFF2 from "./assets/fonts/iosevka-ss08-regular.woff2";
import MonoBoldWOFF from "./assets/fonts/iosevka-ss08-bold.woff";
import MonoBoldWOFF2 from "./assets/fonts/iosevka-ss08-bold.woff2";

import SansWOFF from "./assets/fonts/PublicSans-Regular.woff";
import SansWOFF2 from "./assets/fonts/PublicSans-Regular.woff2";
import SansBoldWOFF from "./assets/fonts/PublicSans-Bold.woff";
import SansBoldWOFF2 from "./assets/fonts/PublicSans-Bold.woff2";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "Iosevka";
    font-weight: normal;
    font-style: normal;
    src: local('Iosevka'),
         local('Iosevka Regular'),
         url(${MonoWOFF}?) format("woff"),
         url(${MonoWOFF2}?) format("woff2")
  }
  @font-face {
    font-family: "Iosevka";
    font-weight: bold;
    font-style: normal;
    src: local('Iosevka Bold'),
         url(${MonoBoldWOFF}?) format("woff"),
         url(${MonoBoldWOFF2}?) format("woff2")
  }

  @font-face {
    font-family: "Public Sans";
    font-weight: normal;
    font-style: normal;
    src: local('Public Sans'),
         local('Public Sans Regular'),
         url(${SansWOFF}?) format("woff"),
         url(${SansWOFF2}?) format("woff2")
  }
  @font-face {
    font-family: "Public Sans";
    font-weight: bold;
    font-style: normal;
    src: local('Publis Sans Bold'),
         url(${SansBoldWOFF}?) format("woff"),
         url(${SansBoldWOFF2}?) format("woff2")
  }

  html, body {
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;
  }

  body {
    background: ${props => props.theme.colors.lightAccent};
    font-family: ${props => props.theme.fonts.sans};
    margin: 0;
  }

  a {
    text-decoration:none;
  }

  input {
    caret-color: ${props => props.theme.colors.accent};
  }

  #root {
    position: relative;
    min-height: 100vh;
  }

  .pin {
    max-width: 440px;
  }

  .pin input {
    border-color: ${props => props.theme.colors.primaries[7]};
    font-family: ${props => props.theme.fonts.mono};
    font-weight: bold;
  }
`;

export default GlobalStyle;
