import React from "react";
import { useStateMachine } from "little-state-machine";
import { Bloc, Flex } from "blocjs";

import { Inputfield } from "../../components";

const FieldGroup = React.forwardRef(
  ({ samplePosition, fieldPosition, errors, ...rest }, ref) => {
    const { state } = useStateMachine();
    const field = state.configDetails[samplePosition].fields[fieldPosition];
    const name = field.name;
    const unit = field.unit;
    const replicas = field.replicas;

    const nameFormatter = (value) => {
      return value.replace(/[^a-zA-Z0-9]/g,'_');
    };

    const getFields = count => {
      const requiredMessage =
            "The field cannot be empty. Please enter a numeric value or NA if you did not measure the parameter or ND if the result was lower than the detection limit";
      const invalidNumberMessage =
            "Invalid number. Please make sure this is a numeric value, use of (,) and inequality symbols (e.g. <, >) is not allowed";
      const acceptableNumberMessage =
            "0 is not a valid number. Please enter a numeric value or NA if you did not measure the parameter or ND if the result was lower than the detection limit";
      return Array(count)
        .fill()
        .map((_, n) => {
          const replicaName = nameFormatter(`${samplePosition}_${name}_${n}`);

          return (
            <Bloc key={n} width={1} mr={n === count - 1 ? 0 : 3}>
              <Inputfield
                key={`input_${replicaName}`}
                name={replicaName}
                defaultValue={replicas[n] || "NA"}
                error={errors[`${replicaName}`]}
                placeholder={`Replica ${n + 1}`}
                ref={ref}
                {...rest}
              />
              <Bloc
                width={1}
                mt={2}
                mb={4}
                color={errors[`${replicaName}`] ? "tomato" : "primaries.7"}
                fontSize={12}
              >
                {errors[`${replicaName}`]?.type === "required" ? requiredMessage : undefined}
                {errors[`${replicaName}`]?.type === "pattern" ? invalidNumberMessage : undefined}
                {errors[`${replicaName}`]?.type === "validate" ? acceptableNumberMessage : undefined}
              </Bloc>
            </Bloc>
          );
        });
    };

    return (
      <Flex flexWrap="wrap" width={1}>
        <Flex width={1}>
          <Bloc color="primaries.0" fontSize={14}>
            {name}
          </Bloc>
          <Bloc color="primaries.3" fontSize={14}>
            &nbsp;{unit}
          </Bloc>
        </Flex>
        <Flex mt={3} width={1}>
          {getFields(replicas.length)}
        </Flex>
      </Flex>
    );
  }
);

export default FieldGroup;
