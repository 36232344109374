import React from "react";
import PropTypes from 'prop-types';
import styled, { css } from "styled-components";
import editIcon from "../../assets/icons/edit.svg";

const IconStyler = styled.span`
color: ${(props) => props.color};
  & img {
    ${(props) =>
    props.small &&
    css`
        width: 1rem !important;
        height: 1rem !important;
        fill: ${(props) => props.color}!important;
        & svg {
          fill: ${(props) => props.color}!important;
          & path {
            fill: ${(props) => props.color}!important;
          }
        }
      `}
    ${(props) =>
    props.med &&
    css`
        width: 20px !important;
        height: 20px !important;
        fill: ${(props) => props.color}!important;
        & svg {
          fill: ${(props) => props.color}!important;
          & path {
            fill: ${(props) => props.color}!important;
          }
        }
      `}
    ${(props) =>
    props.large &&
    css`
        width: 28px !important;
        height: 28px !important;
        fill: ${(props) => props.color}!important;
        & svg {
          fill: ${(props) => props.color}!important;
          & path {
            fill: ${(props) => props.color}!important;
          }
        }
      `}
  }
`;

const Icon = ({ children, ...props }) => {
  return <IconStyler {...props}>{children}</IconStyler>;
};

Icon.propTypes = {
  children: PropTypes.node,
};

const EditIcon = ({fill = '#FFFFFF'}) => {
  return (
    <Icon color={fill} small>
      <img
        src={editIcon}
        alt=""
      />
    </Icon>
  );
};

EditIcon.propTypes = {
  fill: PropTypes.string
};

export default EditIcon;
