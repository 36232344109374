/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { useForm } from "react-hook-form";
import { useStateMachine } from "little-state-machine";
import { useHistory } from "react-router-dom";
import { Flex, Bloc } from "blocjs";

import { updateConfigDataAction, tokenAction, completedAction } from "../../actions";
import { Button, FieldGroup } from "../../components";
import { APIService } from "../../services";

const Sample = ({ samplePosition, ...rest }) => {
  const isRequired = true;
  const nonCommaNumberPattern = /(?:^na$)|(?:^nd$)|^[+]?((\.\d+)|(\d+(\.\d+)?))$/i;
  const isAcceptableNumber = (val) => {
    const n = parseFloat(val);
    return (n > 0 && !isNaN(val)) || /(?:^na$)|(?:^nd$)$/i.test(val);
  };
  const { push } = useHistory();
  const [drafting, setDrafting] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [rerender, setRerender] = useState(false);
  const {
    register,
    getValues,
    errors,
    formState,
    triggerValidation,
    handleSubmit,
  } = useForm({ mode: 'onBlur' });
  const { dirtyFields } = formState;

  const labDetails = JSON.parse(localStorage.getItem("labDetails"));
  const { state, actions } = useStateMachine({
    updateConfigDataAction,
    tokenAction,
    completedAction,
  });

  const title = state.configDetails[samplePosition].name;
  const fields = state.configDetails[samplePosition].fields;

  const pos = parseInt(samplePosition);
  const isFirst = pos === 0;
  const isLast = pos === state.configDetails.length - 1;
  const isSummaryPage = pos === state.configDetails.length;

  const api = new APIService();
  const indexer = (configs, shift = 3) => {
    const result = [];
    let start = 0 + shift;
    configs.forEach((item) => {
      result.push([start, item.fields[0].replicas.length]);
      start += item.fields[0].replicas.length;
    });
    return result;
  };

  useEffect(() => {
    setProcessing(true);
  }, [pos]);

  useEffect(() => {
    setRerender(false);
    api.getResults(labDetails.labId, state.configDetails, samplePosition, (resp) => {
      const values = {};
      fields.forEach((field, i) => {
        field.replicas.forEach((_, j) => {
          values[nameFormatter(`${pos}_${field.name}_${j}`)] = resp.data.range[i][j];
        });
      });

      if (process.env.NODE_ENV !== "production") {
        console.log("Loading....", values);
      }

      if (resp.status === "SUCCESS") {
        actions.updateConfigDataAction([pos, fields[0].replicas.length, values]);
      }

      setRerender(true);
      setProcessing(false);
    });
  }, [pos]);

  const onSubmit = (data, e) => {
    const labId = labDetails.labId;
    const configs = state.configDetails;

    if (process.env.NODE_ENV !== "production") {
      console.log("Submitting....", data);
    }

    setProcessing(true);
    actions.updateConfigDataAction([pos, fields[0].replicas.length, data]);

    api.sendResults(
      labId,
      samplePosition,
      configs[samplePosition],
      indexer(configs)[samplePosition],
      (resp) => {
        setProcessing(false);

        if (isLast) {
          resp["status"] === "SUCCESS" && push(`/summary`);
        } else if (isSummaryPage) {
          // save the submit date
          api.completeForm(state.token, (response) => {
            if (process.env.NODE_ENV !== "production") {
              console.log(response);
            }
          });
          resp["status"] === "SUCCESS" && actions.completedAction(true);
          resp["status"] === "SUCCESS" && push("/feedback");
        } else {
          resp["status"] === "SUCCESS" && push(`/samples/${pos + 1}`);
        }
      }
    );
    // }
  };

  const onDraftSubmit = async () => {
    const labId = labDetails.labId;
    const configs = state.configDetails;

    const validationResult = await triggerValidation([...dirtyFields]);

    if (validationResult) {
      if (process.env.NODE_ENV !== "production") {
        console.log("Drafting....", getValues());
      }
      setDrafting(true);
      actions.updateConfigDataAction([
        pos,
        fields[0].replicas.length,
        getValues(),
      ]);

      api.sendResults(
        labId,
        samplePosition,
        configs[samplePosition],
        indexer(configs)[samplePosition],
        (resp) => {
          resp["status"] === "SUCCESS" && setDrafting(false);
          if (process.env.NODE_ENV !== "production") {
            console.log(resp);
          }
        }
      );
    } else {
      console.error("Error has occured");
    }
  };

  const nameFormatter = (value) => {
    return value.replace(/[^a-zA-Z0-9]/g, '_');
  };

  const getErrorsByName = (name) => {
    return Object.fromEntries(
      Object.entries(errors).filter((value) => {
        return value[0].includes(nameFormatter(name));
      })
    );
  };

  const getFieldGroups = () => {
    return fields.map((item, key) => {
      return (
        <FieldGroup
          key={item.name}
          samplePosition={samplePosition}
          fieldPosition={key}
          ref={register({
            required: isRequired,
            pattern: nonCommaNumberPattern,
            validate: isAcceptableNumber,
          })}
          errors={getErrorsByName(item.name)}
        />
      );
    });
  };

  return (
    rerender ?
      <Flex
        as="form"
        flexWrap="wrap"
        width={1}
        onSubmit={handleSubmit(onSubmit)}
        {...rest}
      >
        <Bloc as="p" fontSize={3} fontWeight="bold" color="surfaces.2">{title}</Bloc>
        {getFieldGroups()}

        <Flex justifyContent="space-between" width={1} my={8}>
          <Button
            value="Previous"
            fontSize={3}
            fontWeight="bold"
            onClick={() => push(`/samples/${pos - 1}`)}
            disabled={isFirst || processing || drafting}
            type="button"
          />
          <Button
            type="button"
            value={drafting ? "Saving draft..." : "Save draft"}
            fontSize={3}
            fontWeight="bold"
            onClick={onDraftSubmit}
            disabled={processing || drafting}
          />
          <Button
            bg={"accent"}
            type="submit"
            value={"Next"}
            fontSize={3}
            fontWeight="bold"
            disabled={processing || drafting}
          />
        </Flex>

      </Flex> :

      <Flex width={1}>
        <Bloc
          mt={91}
          pt={86}
          p="auto"
          width={1}
          height={320}
          color="surfaces.2"
          border={2}
          borderRadius={3}
          borderColor="accents.2"
          backgroundColor="surfaces.0"
          fontSize={3}
          fontWeight="bold"
          textAlign="center"
        >
          Loading Data...
        </Bloc>
      </Flex>
  );
};

Sample.propTypes = {
  samplePosition: PropTypes.number,
};

export default Sample;
