import React from "react";

import styled from "styled-components";
import { Bloc } from "blocjs";

const BlocStyle = styled(Bloc)({
    width: "100%",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: "1rem",
    margin: "1rem auto",
    borderCollapse: "collapse",
    cursor: props => (props.disabled ? "not-allowed" : "pointer"),
    fontFamily: "inherit",
    outline: "none",
    border: "1px solid #000",

});

const Table = ({...rest }) => {
    return (
        <BlocStyle
            as="table"
            {...rest}
        />
    );
};

export default Table;
