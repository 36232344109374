import React, { useState } from "react";
import Select from "react-select";
import { Flex, Box, Bloc } from "blocjs";
import { useHistory } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import useCookie from "@devhammed/use-cookie";
import { useForm, Controller } from "react-hook-form";
import { authenticatedAction } from "../../actions";

import { Button } from "../../components";
import { APIService } from "../../services";

const Feedback = () => {
  const { replace } = useHistory();
  const [processing, setProcessing] = useState(false);
  const [, setAuthenticated] = useCookie("authenticated");
  const { action } = useStateMachine(authenticatedAction);
  const { register, control, formState, errors, handleSubmit } = useForm();

  const { dirty } = formState;

  const labDetails = JSON.parse(localStorage.getItem("labDetails"));

  const api = new APIService();

  const handleLogout = () => {
    action(false);
    setAuthenticated(false);
    localStorage.setItem("labDetails", null);
    replace("/");
  };

  const onSubmit = (data, e) => {
    const labId = labDetails.labId;

    if (process.env.NODE_ENV !== "production") {
      console.log("Submitting....");
    }

    if (dirty) {
      setProcessing(true);

      api.sendFeedback(
        labId,
        data,
        (resp) => {
          if (process.env.NODE_ENV !== "production") {
            console.log(resp);
          }
          setProcessing(false);
          handleLogout();
        });
    }
  };

  const options = [
    {
      value:
        "I provided results for all the parameters and methods in the list",
      label:
        "I provided results for all the parameters and methods in the list",
    },
    {
      value:
        "I provided results only for the parameters I measure and the methods I use in my laboratory",
      label:
        "I provided results only for the parameters I measure and the methods I use in my laboratory",
    },
    {
      value:
        "I could not provide results for all the parameters I measure and the methods I use in my laboratory because the equipment was broken",
      label:
        "I could not provide results for all the parameters I measure and the methods I use in my laboratory because the equipment was broken",
    },
    {
      value:
        "I could not provide results for all the parameters I measure and the methods I use in my laboratory because I did not have sufficient consumables and reagents",
      label:
        "I could not provide results for all the parameters I measure and the methods I use in my laboratory because I did not have sufficient consumables and reagents",
    },
    {
      value:
        "I could not provide results for all the parameters I measure and the methods I use in my laboratory because I did not have staff to perform the analysis",
      label:
        "I could not provide results for all the parameters I measure and the methods I use in my laboratory because I did not have staff to perform the analysis",
    },
    {
      value:
        "I could not provide results for all the parameters I measure and the methods I use in my laboratory because I had too many samples to analyse for my clients",
      label:
        "I could not provide results for all the parameters I measure and the methods I use in my laboratory because I had too many samples to analyse for my clients",
    },
    {
      value:
        "I could not provide results for all the parameters I measure and the methods I use in my laboratory because the soil sample was too small",
      label:
        "I could not provide results for all the parameters I measure and the methods I use in my laboratory because the soil sample was too small",
    },
    {
      value: "Other",
      label: "Other",
    },
    {
      value: "None",
      label: "None",
    }
  ];

  return (
    <Flex
      as="form"
      flexWrap="wrap"
      width={1}
      maxWidth={1240}
      mx="auto"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box width={1} mx={["5.55555%"]}>
        <Bloc as="p">Feedback</Bloc>
        <Bloc color="accents.0" bg="accents.6" p={5} my={5} borderRadius={3}>
          Before submitting your PT results, we would appreciate it if you could
          tell us a bit more about your level of experience in performing the
          analysis for this GLOSOLAN PT round by selecting one of the choices in
          the drop down menu and/or by leaving us a comment if needed
        </Bloc>
        <Bloc as="p" fontSize={14} color="primaries.0">
          Which of the following best describes the level of experience your
          laboratory has in performing the soil analysis you have reported in
          this GLOSOLAN PT round?
        </Bloc>
        <Bloc width={1} fontFamily="mono" color="surfaces.2">
          <Controller
            as={Select}
            name="experience"
            options={options}
            control={control}
            rules={{ required: true }}
            onChange={([selected]) => selected}
          />
        </Bloc>
        <Bloc
          width={1}
          mt={2}
          mb={4}
          color={errors["experience"] ? "tomato" : "primaries.7"}
          fontSize={12}
        >
          {errors["experience"]?.type === "required" && "Required"}
        </Bloc>
        <Bloc as="p" fontSize={14} color="primaries.0">
          Is there anything else you would like to tell GLOSOLAN with regards to
          the soil analysis you performed or in relation to the use of this
          online platform?
        </Bloc>
        <Bloc
          p={3}
          width={1}
          border={1}
          borderRadius={2}
          borderColor={errors["extra"] ? "tomato" : "primaries.7"}
          fontSize={16}
          fontFamily="mono"
          name="extra"
          placeholder="Text goes here"
          rows={8}
          as="textarea"
          ref={register}
        />
        <Flex my={8}>
          <Button
            value="Share Feedback"
            fontSize={3}
            fontWeight="bold"
            disabled={processing}
            type="submit"
          />
        </Flex>
      </Box>
    </Flex>
  );
};

export default Feedback;
