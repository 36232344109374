import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import ReactCodeInput from "react-verification-code-input";
import useCookie from "@devhammed/use-cookie";
import { Flex, Bloc } from "blocjs";

import { APIService } from "../../services";
import { authenticatedAction, loadedAction, tokenAction } from "../../actions";

const Message = ({ status = 0 }) => {
  let messages = {
    "-1": "Form completed or Wrong PIN",
    0: "Fill in the PIN you received with the soil samples",
    1: "Loading...",
    2: "Successful",
  };

  let colors = {
    "-1": "red",
    0: "primaries.0",
    1: "primaries.4",
    2: "accent",
  };
  return (
    <Flex flexWrap="nowrap" justifyContent="center">
      <Bloc as="p" color={colors[status]} fontSize={2} fontWeight="bold" pt={3}>
        {messages[status]}
      </Bloc>
    </Flex>
  );
};

const Login = () => {
  const { replace } = useHistory();
  const [status, setStatus] = useState(0);
  const { state, actions } = useStateMachine({ authenticatedAction, loadedAction, tokenAction });
  const [, setAuthenticated] = useCookie("authenticated");
  // const [, setLabDetails] = useCookie("labDetails");

  const handleComplete = (value) => {
    if (value.length === 10) {
      setStatus(1);
      const api = new APIService();
      api.verifyToken(value, (resp) => {
        if (resp["status"] === "SUCCESS" && resp["data"]) {
          const labDetails = {
            labId: resp["payload"][0][0],
            country: resp["payload"][0][1],
            region: resp["payload"][0][2],
            isOfficial: resp["payload"][0][3],
            name: resp["payload"][0][4],
            acronym: resp["payload"][0][5],
            address: resp["payload"][0][6],
            labHeadNames: resp["payload"][0][7],
            labHeadPosition: resp["payload"][0][8],
            labHeadEmail: resp["payload"][0][9],
            contactPersonNames: resp["payload"][0][10],
            contactPersonPosition: resp["payload"][0][11],
            contactPersonEmail: resp["payload"][0][12],
            registrationDate: resp["payload"][0][13],
            isCompleted: resp["payload"][0][14]
          };
          setStatus(2);
          localStorage.setItem("labDetails", JSON.stringify(labDetails));
          actions.authenticatedAction(true);
          actions.tokenAction(value);
          setAuthenticated(true);
          replace("/profile");
        } else {
          console.error("----------------------");
          console.error(resp);
          console.error("----------------------");
          setStatus(-1);
        }
      });
    }
  };

  return (
    <Flex flexWrap="wrap">
      <Flex justifyContent="center" width={1}>
        <Bloc
          as="h2"
          textAlign="center"
          color="surfaces.2"
          maxWidth={640}
          my={8}
        >
          Welcome to the Global Soil Laboratory Network (GLOSOLAN) platform for
          the online submission of proficiency testing (PT) results
        </Bloc>
      </Flex>

      <Flex justifyContent="center" width={1}>
        {state.completed ? (
          <>
            <Bloc color="accents.0" bg="accents.6" p={5} borderRadius={3} maxWidth={1240}>
              Your results submission was successfully completed <br />
              <br />
              Thank you for participating in the GLOSOLAN PT and for
              submitting the results of your analysis. GLOSOLAN will inform
              you about the performance of your laboratory as soon as possible
              once the results of all participating laboratories are collated.
              If you have any questions please contact the GLOSOLAN
              coordinator, Ms. Lucrezia Caon at lucrezia.caon@fao.org
            </Bloc>
            <Bloc my={7}></Bloc>
          </>
        ) : (
          <Bloc bg="surface" borderRadius={4} maxWidth={512} pt={2} pb={3} px={6}>
            <Bloc as="h2" textAlign="center" color="surfaces.2">
              Unique Identification Code
            </Bloc>
            <Bloc
              borderBottom={1}
              borderBottomColor="accents.4"
              maxWidth={127}
              mx="auto"
              mb={5}
            />
            <ReactCodeInput
              type="number"
              fieldWidth={44}
              fieldHeight={64}
              fields={10}
              className="pin"
              disabled={status > 0}
              loading={status > 0}
              onComplete={(value) => handleComplete(value)}
            />
            <Message status={status} />
          </Bloc>
        )}

      </Flex>

      {state.completed ? (
        <Flex></Flex>
      ) : (
        <Flex justifyContent="center" width={1}>
          <Bloc textAlign="center" color="primaries.4" maxWidth={880} my={8}>
            Before proceeding, please make sure to have all your PT results at
            hand and in the right units of measure.{" "}
            <strong>
              Please note that you can submit your results only once. Once you
              clicked on the "SUBMIT" button, your results cannot be changed
              anymore.
            </strong>
          </Bloc>
        </Flex>
      )}

    </Flex>
  );
};

export default Login;
