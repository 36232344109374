export const loadConfigAction = (state, payload) => {
  return {
    ...state,
    configDetails: [...payload]
  };
};

const nameFormatter = (value) => {
  return value.replace(/[^a-zA-Z0-9]/g,'_');
};

export const updateConfigDataAction = (state, payload) => {
  const pos = payload[0];
  const replicaCount = payload[1];
  const data = payload[2];
  const name = state.configDetails[pos].name;
  const fields = state.configDetails[pos].fields;

  for (let i = 0; i < fields.length; i++) {
    for (let j = 0; j < replicaCount; j++) {
      fields[i].replicas[j] = data[nameFormatter(`${pos}_${fields[i].name}_${j}`)];
    }
  }

  const current = { name, fields };
  const updatedConfigDetails = state.configDetails;
  updatedConfigDetails[pos] = current;

  return {
    ...state,
    configDetails: updatedConfigDetails
  };
};
