import React from "react";
import { Flex, Box, Bloc } from "blocjs";

import glosolan from "../../assets/images/glosolan.png";
import gsp from "../../assets/images/gsp.png";
import phosagro from "../../assets/images/phosagro.png";

const Footer = () => {
  return (
    <Box position="absolute" bottom={0} width={1}>
      <Flex flexWrap="wrap" width={1} maxWidth={1240} mx="auto">
        <Box width={1} mx={["5.55555%"]}>
          <Flex flexWrap="wrap" justifyContent="space-between">
            <Bloc bg="primaries.6" width={1} height={1} />
            <Flex flexWrap="wrap" width={[1, "auto"]}>
              <Bloc
                as="p"
                color="surfaces.2"
                textAlign={["center", "left"]}
                fontSize={14}
                width={1}
                mt={5}
              >
                An initiative of
              </Bloc>
              <Bloc
                backgroundSize="contain"
                backgroundImage={`url(${glosolan})`}
                width={180}
                height={64}
                mt={3}
                mr={["auto", 7]}
                mb={8}
              />
              <Bloc
                backgroundSize="contain"
                backgroundImage={`url(${gsp})`}
                width={60}
                height={64}
                mt={3}
                mb={8}
              />
            </Flex>
            <Flex flexWrap="wrap" width={[1, "auto"]}>
              <Bloc
                as="p"
                color="surfaces.2"
                textAlign={["center", "right"]}
                fontSize={14}
                width={1}
                mt={5}
              >
                Thanks to the financial support of
              </Bloc>
              <Bloc
                backgroundSize="contain"
                backgroundImage={`url(${phosagro})`}
                width={80}
                height={64}
                mt={3}
                ml="auto"
                mr={["auto", 0]}
                mb={8}
              />
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default Footer;
