const getSampleData = (name, fields, units, replicaCount) => {
  let sampleData = {
    name,
    fields: []
  };
  let count = fields.length;
  for (var i = 0; i < count; i++) {
    sampleData["fields"][i] = {
      name: fields[i],
      unit: units[i],
      replicas: []
    };
    for (var j = 0; j < replicaCount; j++) {
      sampleData["fields"][i]["replicas"][j] = null;
    }
  }
  return sampleData;
};

export const configCallback = resp => {
  let samplesData = [];
  if (resp["status"] === "SUCCESS") {
    let sampleNames = resp["data"].sampleNames;
    let units = resp["data"].units;
    let fields = resp["data"].fields;
    let replicas = resp["data"].replicas;

    for (var i = 0; i < sampleNames.length; i++) {
      samplesData[i] = getSampleData(
        sampleNames[i],
        fields,
        units,
        replicas[i]
      );
    }
  }
  return samplesData;
};
