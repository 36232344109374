import React from "react";
import { Redirect, useParams } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import { Box, Flex } from "blocjs";

import { Sample } from "../../components";
import { updateConfigDataAction } from "../../actions";

const SamplePage = props => {
  const { state } = useStateMachine(updateConfigDataAction);
  const formData = state.configDetails;
  const samplesCount = formData.length;
  const { samplePosition } = useParams();
  const pos =
    samplePosition >= 0 && samplePosition < samplesCount ? samplePosition : -1;

  return pos === -1 ? (
    <Redirect to="/page-not-found" />
  ) : (
    <Flex flexWrap="wrap" width={1} maxWidth={1240} mx="auto">
      <Box width={1} mx={["5.55555%"]}>
        <Sample samplePosition={pos} {...props} />
      </Box>
    </Flex>
  );
};

export default SamplePage;
